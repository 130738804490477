<template>
  <b-container class="mt-4">
    <b-form @submit="onSubmit">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="title" class="mr-2 text-white"></label>
          <b-button
            variant="dark"
            class="mr-2"
            to="/clubs"
            v-if="hasRole(['federation'])"
            >Kulüpler</b-button
          >
          <b-button variant="primary" type="submit">Gönder</b-button>
        </template>
        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>
        <b-row>
          <b-col>
            <InputTile
              label="Adı"
              v-model="form.name"
              :required="true"
              type="text"
            />
            <InputTile label="Başkan" v-model="form.minister" type="text" />
            <InputTile label="Genel Sekreter" v-model="form.secretary" type="text" />
            <InputTile label="Kulüp Antrenörü" v-model="form.clubTrainer1" type="text" />
            <InputTile label="Kulüp Antrenörü" v-model="form.clubTrainer2" type="text" />
            <InputTile label="Kulüp Antrenörü" v-model="form.clubTrainer3" type="text" />
            <InputTile label="Bölge" :custom="true">
              <b-form-select v-model="form.region" :options="cities" required>
              </b-form-select>
            </InputTile>
            <InputTile
              label="Tescil No"
              v-model="form.registrationNumber"
              type="text"
            />
            <InputTile
              label="Kuruluş Tarihi"
              v-model="form.foundationDate"
              type="date"
            />
            <InputTile
              label="Kapanış Tarihi"
              v-model="form.closingDate"
              type="date"
            />
            <InputTile label="Adres" v-model="form.address" type="text" />
            <InputTile label="Telefon" v-model="form.phone" type="text" />
          
          </b-col>
          <b-col>
            <InputTile
              label="Akarsu Son Akredite Yılı"
              v-model="form.streamWaterLastAccreditedYear"
              type="text"
              v-if="hasRole(['federation'])"
            />
            <InputTile
              label="Durgunsu Son Akredite Yılı"
              v-model="form.stillWaterLastAccreditedYear"
              type="text"
              v-if="hasRole(['federation'])"
            />
            <InputTile
              label="Deniz Kanosu Son Akredite Yılı"
              v-model="form.seaKayakingLastAccreditedYear"
              type="text"
              v-if="hasRole(['federation'])"
            />
            <InputTile
              label="Dragon Bot Son Akredite Yılı"
              v-model="form.dragonBoatLastAccreditYear"
              type="text"
              v-if="hasRole(['federation'])"
            />
            <InputTile
              label="Rafting Son Akredite Yılı"
              v-model="form.raftingLastAccreditYear"
              type="text"
              v-if="hasRole(['federation'])"
            />
            <InputTile label="Faks" v-model="form.fax" type="text" />
            <InputTile
              label="E-Posta Adresi"
              v-model="form.email"
              type="email"
            />
            <InputTile label="Web Adresi" v-model="form.website" type="text" />
            <InputTile label="Resim Adresi" :custom="true">
              <b-form-file
                v-model="selectedPhotoFile"
                :placeholder="selectedPhotoFileName"
                drop-placeholder="Buraya bırakın..."
                browse-text="Dosya Seç"
              ></b-form-file>
            </InputTile>
            <InputTile label="Takım Fotoğraf Adresi" :custom="true">
              <b-form-file
                v-model="selectedTeamPhotoFile"
                :placeholder="selectedTeamPhotoFileName"
                drop-placeholder="Buraya bırakın..."
                browse-text="Dosya Seç"
              ></b-form-file>
            </InputTile>
            <InputTile
              label="Açıklama"
              v-model="form.description"
              type="text"
            />
            <InputTile
              label="Şifre"
              v-model="form.password"
              :required="true"
              type="text"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import InputTile from "./common/InputTile.vue";
import cities from "@/store/constants/cities";
export default {
  components: { InputTile },
  name: "ClubForm",
  data() {
    return {
      selectedPhotoFile: null,
      selectedPhotoFileName: "Dosya seçin veya buraya sürükleyin...",
      selectedTeamPhotoFile: null,
      selectedTeamPhotoFileName: "Dosya seçin veya buraya sürükleyin...",
      cities: cities,
    };
  },
  props: ["title", "submit", "form"],
  beforeUpdate() {
    if (this.form.photoAddress) this.selectedPhotoFileName = "Hazırda Var";
    if (this.form.teamPhotoAddress)
      this.selectedTeamPhotoFileName = "Hazırda Var";
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.selectedPhotoFile) {
        this.form.changephotoAddress = this.form.photoAddress;
        this.form.photoAddress = this.selectedPhotoFile;
      }
      if (this.selectedTeamPhotoFile) {
        this.form.changeteamPhotoAddress = this.form.teamPhotoAddress;
        this.form.teamPhotoAddress = this.selectedTeamPhotoFile;
      }
      this.submit(this.form);
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.buttonBar {
  text-align: end;
}
.center {
  text-align: center;
}
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>