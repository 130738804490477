<template>
  <ClubForm v-bind="props" :form="form" />
</template>

<script>
import ClubForm from "@/components/forms/ClubForm.vue";
import { mapGetters } from "vuex";
export default {
  components: { ClubForm },
  computed: {
    ...mapGetters(["getUser", "getSingleClub"]),
    form: {
      get() {
        return this.getSingleClub;
      },
    },
  },
  created() {
    if (this.$store.getters.checkToken.isFederation) {
      this.$store.dispatch("initSingleClub", this.$route.params.id);
    } else if (this.$store.getters.checkToken.isClub) {
      this.$store.dispatch("initSingleClub", this.getUser.id);
    }
  },
  data() {
    return {
      props: {
        title: "Kulüp Güncelle",
        submit: (form) => {
          this.$store.dispatch("updateClub", form).then(() => {
            if (!this.$store.getters.checkToken.isClub) {
              this.$router.push("/clubs");
            } else {
              this.$router.push({
                name: "ClubDetail",
                params: { id: this.$store.getters.getUser.id },
              });
            }
          });
        },
      },
    };
  },
};
</script>

<style scoped>
</style>